import React from "react";
import FeatureCard from "./components/FeatureCard";
import {
  MERGE_DOCS_AUTOMATIC_WEBHOOKS_GUIDE,
  MERGE_DOCS_BASE_URL,
  MERGE_PRICING_PAGE,
} from "components/docs/navigation/links";
import { Alert, APICategory } from "@merge-api/merge-javascript-shared";
interface ThirdPartyWebhooksTabProps {
  categoryName: APICategory;
}

const ThirdPartyWebhooksTab = ({ categoryName }: ThirdPartyWebhooksTabProps) => {
  return (
    <>
      {categoryName !== APICategory.filestorage && (
        <Alert className="text-base mb-6" showWarningIcon>
          This category has limited support for third-party webhooks. Support depends on the API
          provider.
        </Alert>
      )}
      <div className="flex flex-col s-f-bp:flex-row s-f-bp:space-x-6 s-f-bp:space-y-0 space-y-6 w-full">
        <FeatureCard
          color="gradient"
          title="Automatic webhooks"
          learnMoreLink={MERGE_DOCS_BASE_URL + MERGE_DOCS_AUTOMATIC_WEBHOOKS_GUIDE}
        >
          <p className="mb-3">
            Merge automatically creates webhooks in supported third parties to send data payloads to
            Merge when data changes in real-time, eliminating the need for manual setup.
          </p>
          <p className="mb-0">
            Third-party webhooks are a premium plan feature.{" "}
            <a href={MERGE_PRICING_PAGE} target="_blank">
              View plans
            </a>
          </p>
        </FeatureCard>
        <FeatureCard
          color="blue"
          icon="link"
          title="Manual webhooks"
          learnMoreLink={MERGE_DOCS_BASE_URL + MERGE_DOCS_AUTOMATIC_WEBHOOKS_GUIDE}
        >
          <p className="mb-3">
            Some platforms without automatic webhooks allow manual webhook configuration by your
            users in their third-party app UI. Manual webhooks also send data payloads to Merge when
            data changes in real-time.
          </p>
          <p className="mb-0">
            Third-party webhooks are a premium plan feature.{" "}
            <a href={MERGE_PRICING_PAGE} target="_blank">
              View plans
            </a>
          </p>
        </FeatureCard>
      </div>
    </>
  );
};

export default ThirdPartyWebhooksTab;
