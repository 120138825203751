import { APICategory } from "@merge-api/merge-javascript-shared";
import { useAvailableActions } from "hooks/useAvailableActions";
import { OpenAPIV3 } from "openapi-types";
import React, { useState, useRef, useEffect } from "react";
import {
  Integration,
  DropdownOption,
  getAllIntegrations,
  getSupportedFeatureIntegrationAndModelDetails,
  SELECT_ALL,
  handleSelect,
} from "./helpers";
import MultiSwitch from "./components/MultiSwitch";
import DeletionDetectionTab from "./DeletionDetectionTab";
import ThirdPartyWebhooksTab from "./ThirdPartyWebhooksTab";
import { ActionMeta } from "react-select";
import MultiSelectSearch from "./components/MultiSelectSearch";
import SupportedFeaturesTable from "./components/SupportedFeaturesTable";

interface SupportedFieldsPageProps {
  pageContext: {
    key: APICategory;
    integrations: {
      integration: Integration;
    };
    categorySchemas: Record<APICategory, OpenAPIV3.Document>;
  };
}

const SupportedFieldsPage = ({ pageContext }: SupportedFieldsPageProps) => {
  const { key: categoryName, integrations } = pageContext;

  const allAvailableActions = useAvailableActions();

  const allAvailableActionsForCategory = allAvailableActions[categoryName] ?? {};

  const [isOnDeletedDataPage, setIsOnDeletedDataPage] = useState<boolean>(true);

  const commonModelNames = Object.keys(allAvailableActionsForCategory).filter(
    (availableActionKey) => {
      return (
        allAvailableActionsForCategory[availableActionKey] &&
        allAvailableActionsForCategory[availableActionKey]["GET"]
      );
    },
  );

  const integrationNames = getAllIntegrations(integrations);

  const supportedFeatures = getSupportedFeatureIntegrationAndModelDetails(
    allAvailableActions,
    categoryName,
    commonModelNames,
    integrationNames,
  );

  const [selectedCommonModels, setSelectedCommonModels] = useState(commonModelNames);

  const [selectedCommonModelsDropdown, setSelectedCommonModelsDropdown] = useState([
    SELECT_ALL,
    ...commonModelNames,
  ]);

  const [selectedIntegrationsDropdown, setSelectedIntegrationsDropdown] = useState([
    SELECT_ALL,
    ...integrationNames,
  ]);

  const [selectedIntegrations, setSelectedIntegrations] = useState(integrationNames);

  return (
    <div className="w-full flex flex-col md:px-10 md:py-10 m-auto sm:px-1 sm:py-10">
      <div className="text-[26px] leading-[36px] font-semibold mb-[18px] text-black">
        Supported integration features
      </div>
      <div className="mb-9">
        See which features are supported for each integration and compare coverage across platforms.
      </div>
      <hr className="border-t border-gray-10 m-0 p-0" />
      <div className="flex flex-col s-f-bp:flex-row s-f-bp:space-x-6 my-9 s-f-bp:items-center">
        <div className="mb-6 s-f-bp:mb-0 items-start">
          <MultiSwitch
            isOnDeletedDataPage={isOnDeletedDataPage}
            setIsOnDeletedDataPage={setIsOnDeletedDataPage}
          />
        </div>
        <div className="grid grid-cols-1 s-f-bp:grid-cols-2 gap-6 w-full">
          <MultiSelectSearch
            title="Select Common Models"
            dropDownOptions={[SELECT_ALL, ...commonModelNames]}
            handleOptionSelect={(
              selectedOptions: DropdownOption[],
              actionMeta: ActionMeta<DropdownOption>,
            ) =>
              handleSelect({
                selectedOptions,
                actionMeta,
                setOptions: setSelectedCommonModels,
                setDropdownOptions: setSelectedCommonModelsDropdown,
                input: commonModelNames,
              })
            }
            selectedOptions={selectedCommonModelsDropdown}
          />
          <MultiSelectSearch
            title="Select integrations"
            dropDownOptions={[SELECT_ALL, ...integrationNames]}
            handleOptionSelect={(
              selectedOptions: DropdownOption[],
              actionMeta: ActionMeta<DropdownOption>,
            ) =>
              handleSelect({
                selectedOptions,
                actionMeta,
                setOptions: setSelectedIntegrations,
                setDropdownOptions: setSelectedIntegrationsDropdown,
                input: integrationNames,
              })
            }
            selectedOptions={selectedIntegrationsDropdown}
          />
        </div>
      </div>
      {isOnDeletedDataPage ? (
        <DeletionDetectionTab />
      ) : (
        <ThirdPartyWebhooksTab categoryName={categoryName} />
      )}
      <SupportedFeaturesTable
        supportedFeatures={supportedFeatures}
        selectedCommonModelNames={selectedCommonModels}
        selectedIntegrationNames={selectedIntegrations}
        allIntegrations={integrations}
        isOnDeletedDataPage={isOnDeletedDataPage}
        categoryName={categoryName}
      />
    </div>
  );
};

export default SupportedFieldsPage;
